<template>
  <div class="background">
    <v-container class="spacing-playground pa-lg-10"
      fluid>
      <v-card class="d-flex flex-column"
              style="margin:0 auto;"
              max-width="1200px"      
              :loading="loading">
        <v-card-title>
          <div class="logo">
            <app-logo/>
          </div>
          {{titulo}}
        </v-card-title>
        <v-divider/>
        <div :style="`overflow: auto; max-height: calc(100vh - ${190 + ($vuetify.breakpoint.lgAndUp ? 54 : 34)}px)`">
          <v-card-subtitle>
            Seja bem vindo ao sistema de Gestão da Federação Baiana, Capixaba e Mineira de Golfe. Informe corretamente seus dados, para que você possa
            acompanhar suas faturas, incrições em campoentaos e etc. Ao final do cadastro, enviaremos um
            e-mail com as intruções de acesso.
          </v-card-subtitle>
          <v-divider/>
          <v-card-text class="py-4 flex-grow-1">
            <v-form v-if="!cadastroConcluido" ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
              <CadastreSeForm :value="item" :ePrematricula="ePrematricula" :loading="loading"/>
            </v-form>
            <div v-else >
              <h1>Obrigado por se associar conosco.</h1>
            </div>
          </v-card-text>
        </div>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn v-if="!cadastroConcluido" color="primary" @click="save" :disabled="!formValid" v-show="salvarVisivel">Salvar</v-btn>
          <v-icon large v-else color="green">mdi-emoticon-cool</v-icon>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import AppLogo from "../../components/app/AppLogo";
import CadastreSeForm from "../../components/shared/forms/CadastreSeForm";
import {clienteNovoCadastro} from "../../api/clientes";
import {ApiError} from "../../api/core";

export default {
  components: {AppLogo, CadastreSeForm},
  name: "CadastreSe",
  props: ['ePrematricula','titulo', ],
  data() {
    return {
      item: {
        formaPgtoAnuidade: "AV",
        valorAnuidade: 0,
        descontoAnuidade: 0,
        valAnuidadeComDesconto: 0,
      },
      formValid: false,
      loading: false,
      salvarVisivel: true,
      cadastroConcluido: false,
    }
  },
  methods: {
    async save() {
      if(!this.$refs.form.validate()) return;
      try {
        this.salvarVisivel = false;
        this.loading = true;
        await clienteNovoCadastro(this.item)
        this.$emit('input', false);
        this.$emit('onSave');
        this.$swal({
          title: 'Acessar sua conta?',
          html: `Seu cadastro foi concluído com sucesso. Enviamos um e-mail com algumas instruções importantes. Caso queira, podemos te direcionar para a tela de login, para acessar sua conta.`,
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'Não, obrigado',
          confirmButtonText: 'Sim, quero acessar minha conta'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({name: 'LoginCliente'});
          } else {
            this.concluirCadastro();
          }
        });
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível realizar o cadastro. Entre em contato com a Federação Baiana, Capixaba e Mineira de Golfe!'}.`, {
            timeout: 6000
          })
        }
        this.salvarVisivel = true;
      } finally {
        this.loading = false;
      }
    },
    concluirCadastro(){
      this.cadastroConcluido = true;
      this.salvarVisivel = false;
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

.background {
  background-image: url('../../assets/backgrounds/bg01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  flex: 1;

  .logo {
    padding: 5px;

    img {
      height: 60px;
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
}

</style>